import React from 'react';
import { applyColumnsConfig, ColumnsConfig, cx, DataRowProps, DataSourceState, getDefaultColumnsConfig, Lens, ScrollManager,
    UuiContexts, uuiContextTypes } from '@epam/uui';
import { ColumnsConfigurationModal, DataTableHeaderRow, DataTableRow, DataTableScrollRow, DataTableMods, DataTableProps } from './';
import { FlexRow, IconButton, VirtualList, Text } from '../';
import * as css from './DataTable.scss';
import * as searchIcon from '../icons/search-24.svg';

interface DataTableState {
    columnsConfig: ColumnsConfig;
}

export class DataTable<TItem, TId = any> extends React.Component<DataTableProps<TItem, TId> & DataTableMods, DataTableState> {
    static contextTypes = uuiContextTypes;
    context: UuiContexts;

    state: DataTableState = {
        columnsConfig: this.getUserColumnsConfig() || getDefaultColumnsConfig(this.props.columns),
    };

    scrollManager = new ScrollManager();
    lens = Lens.onEditableComponent<DataSourceState>(this);

    private renderRow = (props: DataRowProps<TItem, TId>) => (
        <DataTableRow
            key={ props.rowKey }
            size={ this.props.size }
            background={ this.props.rowBackground }
            borderBottom={ this.props.border }
            columns={ this.getVisibleColumns() }
            { ...props }
        />
    )

    getVisibleColumns() {
        return applyColumnsConfig(this.props.columns, this.state.columnsConfig);
    }

    getUserColumnsConfig(): ColumnsConfig {
        return this.context?.uuiUserSettings.get(this.props.settingsKey);
    }

    setColumnsConfig = (config: ColumnsConfig) => {
        this.setState({columnsConfig: config});
        this.props.settingsKey && this.context.uuiUserSettings.set(this.props.settingsKey, config);
    }

    onConfigurationButtonClick = () => {
        this.context.uuiModals.show<ColumnsConfig>(modalProps => (
                <ColumnsConfigurationModal
                    { ...modalProps }
                    columns={ this.props.columns }
                    columnsConfig={ this.state.columnsConfig }
                    defaultConfig={ getDefaultColumnsConfig(this.props.columns) }
                />
            )).then(this.setColumnsConfig);
    }

    renderNoResultsBlock = () => {
        const renderNoResults = () => (
            <div className={ cx(css.noResults) }>
                <IconButton icon={ searchIcon } cx={ css.noResultsIcon } />
                <Text fontSize='16' font='sans-semibold'>No Results Found</Text>
                <Text fontSize='14'>We can't find any item matching your request</Text>
            </div>
        );

        return this.props.renderNoResultsBlock ? this.props.renderNoResultsBlock() : renderNoResults();
    }

    render() {
        const { value, exactRowsCount } = this.props;
        const renderRow = this.props.renderRow || this.renderRow;

        const rows = this.props.getRows()
            .map((row: DataRowProps<TItem, TId>) => renderRow({ ...row, scrollManager: this.scrollManager, columns: this.getVisibleColumns() }));

        return (
            <>
                <DataTableHeaderRow
                    key='header'
                    scrollManager={ this.scrollManager }
                    columns={ this.getVisibleColumns() }
                    onConfigButtonClick={ this.props.showColumnsConfig && this.onConfigurationButtonClick }
                    selectAll={ this.props.selectAll }
                    size={ this.props.size }
                    textCase={ this.props.headerTextCase }
                    { ...this.lens.toProps() }
                />
                <FlexRow key='body' topShadow background='white' cx={ css.body }>
                    { exactRowsCount !== 0 ? (
                        <VirtualList
                            { ...this.lens.toProps() }
                            onScroll={ this.props.onScroll }
                            rows={ rows }
                            rowsCount={ this.props.rowsCount }
                            focusedIndex={ this.props.value.focusedIndex }
                        />
                    ) : this.renderNoResultsBlock() }
                </FlexRow>
                <DataTableScrollRow key='scroll' scrollManager={ this.scrollManager } columns={ this.getVisibleColumns() }/>
            </>
        );
    }
}
