import {DataSourceState, ILazyDataSource} from "../types";
import { DataSourceListProps, IDataSourceView } from './types';
import {BaseListView, BaseListViewOptions, BaseListViewParams} from "./BaseListView";

export interface LazyListViewParams<TItem, TId, TFilter> extends BaseListViewParams<TItem, TId, TFilter>  {
    dataSource: ILazyDataSource<TItem, any, TId>;
}

export interface ILazyListView<TItem, TId, TFilter> extends IDataSourceView<TItem, TId, TFilter> {}

export class LazyListView<TItem, TId, TFilter = any> extends BaseListView<TItem, TId, TFilter> implements ILazyListView<TItem, TId, TFilter> {
    value: DataSourceState<TFilter, TId>;

    constructor(public params: LazyListViewParams<TItem, TId, TFilter>) {
        super(params);
        this.value = params.value;
        this.options = params.options || {};
    }

    _setValue(value: DataSourceState<TFilter, TId>): void {
        this.value = value;
        this.updateCheckedLookup(this.value.checked);
    }

    _setOptions(options: BaseListViewOptions<TItem, TId>) {
        this.options = options || {};
        this.updateCheckedLookup(this.value.checked);
    }

    public getById = (id: TId, index: number) => {
        const item = this.params.dataSource.getById(id);
        if (item !== null) {
            return this.getRowProps(item, index);
        } else {
            return this.getLoadingRow('_loading_' + id as any, index);
        }
    }

    public getVisibleRows = () => {
        const from = this.value.topIndex;
        const count = this.value.visibleCount;

        return this.params.dataSource.getList(from, count, this.value).items.map((item, index) => {
            if (item === null) {
                return this.getLoadingRow('_loading_' + index  as any, index);
            }

            const rowProps = this.getRowProps(item, from + index);
            return rowProps;
        });
    }

    getListProps = (): DataSourceListProps => {
        const list = this.params.dataSource.getList(0, 0, this.value); // just need counts, not items.

        return {
            rowsCount: (list.exactCount != null) ? list.exactCount : list.knownCount + 50,
            exactRowsCount: list.exactCount,
            knownRowsCount: list.knownCount,
        };
    }
}
