import * as React from 'react';
import {LazyDataSourceApi, LazyDataSourceApiRequestOptions, DataSourceState, ILazyDataSource} from "./types";
import { LazyListView, BaseListViewOptions, ILazyListView } from './views';
import { ListApiCache } from './ListApiCache';
import { BaseDataSource } from "./BaseDataSource";
import { useEffect } from "react";


export interface LazyDataSourceProps<TItem, TId, TFilter> {
    api: LazyDataSourceApi<TItem, TId, TFilter>;
    filter?: TFilter;
    getId?(item: TItem): TId;
    maxCacheSize?: number;
}

export class LazyDataSource<TItem = any, TFilter = any, TId = any> extends BaseDataSource<TItem, TId, LazyDataSourceProps<TItem, TId, TFilter>> implements ILazyDataSource<TItem, TFilter, TId> {
    cache: ListApiCache<TItem, TId, TFilter> = null;

    constructor(public params: LazyDataSourceProps<TItem, TId, TFilter>) {
        super(params);
        this.initCache();
    }

    public getById = (id: TId) => {
        return this.cache.byId(id);
    }

    private initCache() {
        this.cache = new ListApiCache({
            api: this.params.api,
            getId: this.getId,
            onUpdate: this.updateViews,
            maxCacheSize: this.props.maxCacheSize,
        });
    }

    setItem(item: TItem) {
        this.cache.setItem(item);
    }

    public clearCache() {
        this.initCache();
        this.updateViews();
    }

    getView = (value: DataSourceState<any, TId>, onValueChange: (val: DataSourceState<any, TId>) => any, options?: BaseListViewOptions<TItem, TId>): ILazyListView<TItem, TId, TFilter> => {
        const view = this.views.get(onValueChange) as LazyListView<TItem, TId, TFilter>;
        if (view) {
            view._setValue(value);
            view._setOptions(options);
            return view;
        } else {
            const newView: any = new LazyListView({value, onValueChange, dataSource: this, options: options});
            this.views.set(onValueChange, newView);
            return newView;
        }
    }

    useView(value: DataSourceState<any, TId>, onValueChange: (val: DataSourceState<any, TId>) => any, options?: BaseListViewOptions<TItem, TId>): ILazyListView<TItem, TId, TFilter> {
        useEffect(() => () => this.unsubscribeView(onValueChange), [this]);

        return this.getView(value, onValueChange, options);
    }

    public getList(from: number, count: number, options: LazyDataSourceApiRequestOptions<TItem, TFilter>) {
        return this.cache.query({ ...options, filter: { ...this.params.filter, ...options.filter }, range: { from, count }});
    }
}
