import * as React from 'react';
import * as types from '@epam/uui';
import * as css from './Text.scss';
import cx from 'classnames';
import {svc} from "@epam/app/src/services";
import {UuiContexts, uuiContextTypes} from "@epam/uui";

export type TextProps = types.IHasCX & types.IHasChildren & types.IClickable & types.IAnalyticableClick;

export class Text extends React.Component<TextProps> {
    static contextTypes = uuiContextTypes;
    context: UuiContexts;
    
    onClick = (e?: any) => {
        this.props.onClick(e);
        this.context.uuiAnalytics.sendEvent(this.props.clickAnalyticsEvent);
    }
    
    render() {
        return (
            <div onClick={ this.props.onClick && this.onClick } className={ cx(this.props.cx, css.container) }>
                { this.props.children }
            </div>
        );
    }
}