import { useMemo, useRef, useEffect } from 'react';
import { LazyDataSource, LazyDataSourceProps } from '../LazyDataSource';
import { useReducer } from 'react';

export function useLazyDataSource<TItem, TId, TFilter >(params: LazyDataSourceProps<TItem, TId, TFilter>) {
    const dataSource = useMemo(() => new LazyDataSource({
        ...params,
    }), Object.keys(params).map(key => (params as any)[key]));

    return dataSource;
}