import { useMemo } from 'react';
import { AsyncDataSource, AsyncDataSourceParams } from '../AsyncDataSource';
import { useReducer } from 'react';

export function useAsyncDataSource<TItem, TId, TFilter >(params: AsyncDataSourceParams<TItem, TId, TFilter>) {
    const dataSource = useMemo(() => new AsyncDataSource({
        ...params,
    }), []);

    return dataSource;
}