import React from 'react';
import * as css from './TextPlaceholder.scss';
import * as styles from '../../assets/styles/scss/loveship-color-vars.scss';
import * as types from '../types';
import { IHasCX } from '@epam/uui';

export interface TextPlaceholderProps extends IHasCX {
    wordsCount?: number;
    color?: types.EpamColor;
    isNotAnimated?: boolean;
}

export class TextPlaceholder extends React.Component<TextPlaceholderProps> {

    getStyle() {
        return [
            this.props.cx,
            css.loadingWord,
            styles['color-' + (this.props.color || 'night100')],
            !this.props.isNotAnimated && css.animatedLoading,
        ];
    }

    render() {
        const pattern = '0';
        let words = [];
        for (let i = 0; i < (this.props.wordsCount || 1); i++) {
            let lengthWord = Math.floor(Math.random() * 10 + 8);
            words.push(pattern.repeat(lengthWord));
        }

        return <span className={ this.getStyle().join(" ") }>{ words.join(' ') }</span>;
    }
}
