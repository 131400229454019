import { DataRowOptions, DataRowProps, ICheckable, IEditable, SortingOption } from "../../../types";
import { DataSourceState, IDataSource } from "../types";
import { DataSourceListProps, IDataSourceView } from './types';

export interface BaseListViewParams<TItem, TId, TFilter> extends IEditable<DataSourceState<TFilter, TId>> {
    dataSource: IDataSource<TItem, TId, TFilter>;
    options: BaseListViewOptions<TItem, TId>;
}

export interface BaseListViewOptions<TItem, TId> {
    getRowOptions?(item: TItem, index: number): DataRowOptions<TItem, TId>;
}

export abstract class BaseListView<TItem, TId, TFilter> implements IDataSourceView<TItem, TId, TFilter> {
    public value: DataSourceState<TFilter, TId> = {};
    protected options: BaseListViewOptions<TItem, TId> = {};
    protected checkedByKey: Record<string, boolean> = {};
    public selectAll?: ICheckable;

    abstract getById(id: TId, index: number): DataRowProps<TItem, TId>;
    abstract getVisibleRows(): DataRowProps<TItem, TId>[];
    abstract getListProps(): DataSourceListProps;
    abstract _setValue(value: DataSourceState<TFilter, TId>): void;
    abstract _setOptions(options: BaseListViewOptions<TItem, TId>): void;

    _forceUpdate() {
        this.params.onValueChange({ ...this.value });
    }

    protected constructor(protected params: BaseListViewParams<TItem, TId, TFilter>) {
        this.updateCheckedLookup(params.value && params.value.checked);
        this.value = params.value;
        this.options = params.options || {};
    }

    protected updateCheckedLookup(checked: TId[]) {
        this.checkedByKey = {};
        (checked || []).forEach(id => {
            this.checkedByKey[this.idToKey(id)] = true;
        });
    }

    protected handleCheckedChange(checked: TId[]) {
        this.params.onValueChange({ ...this.value, checked });
        this.updateCheckedLookup(checked);
    }

    protected idToKey(id: TId) {
        return JSON.stringify(id);
    }

    protected setObjectFlag(object: any, key: string, value: boolean) {
        return { ...object, [key]: value };
    }

    public getSelectedRows(): DataRowProps<TItem, TId>[] {
        if (this.value.selectedId !== null && this.value.selectedId !== undefined) {
            return [this.getById(this.value.selectedId, 0)];
        } else if (this.value.checked) {
            return this.value.checked.map((id, n) => this.getById(id, n));
        }
        return [];
    }

    protected handleOnCheck = (rowProps: DataRowProps<TItem, TId>) => {
        let checked = this.value && this.value.checked || [];
        if (rowProps.isChecked) {
            checked = checked.filter(id => id !== rowProps.id);
            this.handleCheckedChange(checked);
        } else {
            checked = [...checked, rowProps.id];
            this.handleCheckedChange(checked);
        }
    }

    protected handleOnSelect = (rowProps: DataRowProps<TItem, TId>) => {
        this.params.onValueChange({
            ...this.value,
            selectedId: rowProps.id,
        });
    }

    protected handleOnFocus = (focusIndex: number) => {
        if (this.params.onValueChange) {
            this.params.onValueChange({
                ...this.value,
                focusedIndex: focusIndex,
            });
        }
    }

    protected handleOnFold = (rowProps: DataRowProps<TItem, TId>) => {
        if (this.params.onValueChange) {
            this.params.onValueChange({
                ...this.value,
                folded: this.setObjectFlag(this.value && this.value.folded, rowProps.rowKey, !rowProps.isFolded),
            });
        }
    }

    protected handleSort = (sorting: SortingOption) => {
        if (this.params.onValueChange) {
            this.params.onValueChange({
                ...this.value,
                sorting: [sorting],
            });
        }
    }

    protected getRowProps(item: TItem, index: number): DataRowProps<TItem, TId> {
        const id = this.params.dataSource.getId(item);
        const key = this.idToKey(id);

        const value = this.value;

        const rowOptions = this.options.getRowOptions && this.options.getRowOptions(item, index);

        const isCheckable = rowOptions && rowOptions.checkbox && rowOptions.checkbox.isVisible && !rowOptions.checkbox.isDisabled;
        const isSelectable = rowOptions && rowOptions.isSelectable;

        const rowProps = {
            id,
            rowKey: key,
            index,
            value: item,
            depth: 0,
            ...rowOptions,
            isFocused: value.focusedIndex === index,
            isChecked: this.checkedByKey[key],
            isSelected: value.selectedId === id,
            onCheck: isCheckable && this.handleOnCheck,
            onSelect: rowOptions && rowOptions.isSelectable && this.handleOnSelect,
            onFocus: (isSelectable || isCheckable) && this.handleOnFocus,
        } as DataRowProps<TItem, TId>;

        return rowProps;
    }

    protected getLoadingRow(id: any, index: number = 0): DataRowProps<any, any> {
        return {
            id,
            rowKey: JSON.stringify(id),
            index,
            isLoading: true,
            depth: 0,
        };
    }
}

