import { BaseDataSourceProps } from "./BaseDataSource";
import { ArrayDataSourceOptions, ArrayDataSource } from "./ArrayDataSource";
import { ArrayListViewOptions, IArrayListView } from './views/ArrayListView';
import { LoadingListView } from './views/LoadingListView';
import { DataSourceState } from './types';

export interface AsyncDataSourceApiRequest<TFilter = {}> {
    filter?: TFilter;
}

export interface AsyncDataSourceParams<TItem, TId, TFilter> extends ArrayDataSourceOptions<TItem, TId>, BaseDataSourceProps<TItem, TId>  {
    api(request?: AsyncDataSourceApiRequest<TFilter>): Promise<TItem[]>;
}

export class AsyncDataSource<TItem = any, TId = any, TFilter = any> extends ArrayDataSource<TItem, TId> {
    api: (request?: AsyncDataSourceApiRequest<TFilter>) => Promise<TItem[]> = null;

    constructor(params: AsyncDataSourceParams<TItem, TId, TFilter>) {
        super({
            ...params,
            items: [],
        });
        this.api = params.api;
    }

    isLoading: boolean = false;
    isLoaded: boolean = false;

    private load() {
        if (!this.isLoading) {
            this.isLoading = true;
            this.api().then(res => {
                this.isLoading = false;
                this.isLoaded = true;
                this.updateIndexes(res);
                const loadingViews = new Map(this.views);
                this.views.clear();
                loadingViews.forEach(view => view._forceUpdate());
            });
        }
    }

    reload() {
        this.isLoading = false;
        this.isLoaded = false;

        this.byKey = {};
        this.byParentKey = {};
        this.nodes = [];
        this.rootNodes = [];
        this.maxDepth = null;

        const views = new Map(this.views);
        this.views.clear();
        views.forEach(view => view._forceUpdate());
    }

    getView(value: DataSourceState<any, TId>, onValueChange: (val: DataSourceState<any, TId>) => any, options?: ArrayListViewOptions<TItem, TId, TFilter>): IArrayListView<TItem, TId, TFilter> {
        if (!this.isLoaded) {
            this.load();
            const view = this.views.get(onValueChange) as IArrayListView<TItem, TId, TFilter>;
            if (view) {
                view._setValue(value);
                return view;
            } else {
                const newView: any = new LoadingListView({value, onValueChange});
                this.views.set(onValueChange, newView);
                return newView;
            }
        }

        return super.getView(value, onValueChange, options);
    }
}

