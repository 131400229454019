import * as React from 'react';
import { uuiContextTypes, DataSourceState, SortDirection, DataColumnProps, DataTableHeaderRowProps, Lens } from '@epam/uui';
import { DataTableRowContainer } from './DataTableRowContainer';

const uuiDataTableHeaderRow = {
    uuiTableHeaderRow: 'uui-table-header-row',
};

export class DataTableHeaderRow<T> extends React.Component<DataTableHeaderRowProps<T, any>, {}> {
    static contextTypes = uuiContextTypes;

    lens = Lens.onEditableComponent<DataSourceState>(this);
    sortLens = this.lens.prop('sorting');
    filterLens = this.lens.prop('filter');

    renderCell = (column: DataColumnProps<any, any>, idx: number) => {
        const sorting = this.sortLens.index(0).default({ field: null, direction: 'asc' }).get();
        const cellProps = {
            column,
            key: column.key,
            selectAll: this.props.selectAll,
            isFirstColumn: idx === 0,
            isLastColumn: idx === this.props.columns.length - 1,
            isFilterActive: column.isFilterActive && column.isFilterActive(this.filterLens.default({}).get(), column),
            sortDirection: sorting.field === column.key ? (sorting.direction || 'asc') : null,
            onSort: (dir: SortDirection) => this.props.onValueChange({
                ...this.props.value,
                sorting: (sorting.field !== column.key || sorting.direction !== dir) ? [{ field: column.key, direction: dir }] : [],
            }),
            //onConfigButtonClick: () => this.props.onConfigButtonClick({ columns: this.props.columns, value: this.props.value, onValueChange: this.props.onValueChange }),
            renderFilter: () => column.renderFilter(this.filterLens),
        };

        return this.props.renderCell(cellProps);
    }

    render() {
        return (
            <DataTableRowContainer
                cx={ [this.props.cx, uuiDataTableHeaderRow.uuiTableHeaderRow] }
                scrollManager={ this.props.scrollManager }
                columns={ this.props.columns }
                renderCell={ this.renderCell }
                renderConfigButton={ this.props.onConfigButtonClick && this.props.renderConfigButton }
            />
        );
    }
}
