import {BaseListView} from "./BaseListView";
import { DataSourceState } from '../types';
import { DataSourceListCounts } from './types';

export class LoadingListView<TId> extends BaseListView<any, any, any> {
    value: DataSourceState<any>;

    constructor(public params: any) {
        super(params);
        this.value = params.value;
        this.getVisibleRows = this.getVisibleRows.bind(this);
    }

    _setValue(value: any) {
        this.value = value;
    }

    _setOptions(options: any) {
        this.options = options || {};
    }

    getById(id: TId) {
        return this.getLoadingRow('_loading_' + id as any);
    }

    getVisibleRows() {
        let result = [];
        for (let i = 0; i < this.value.visibleCount; i++) {
            result.push(this.getLoadingRow('_loading_' + (this.value.topIndex + i) as any));
        }
        return result;
    }

    getListProps(): DataSourceListCounts {
        return {
            rowsCount: 50,
            exactRowsCount: null,
            knownRowsCount: 50,
        };
    }

    getSelectedRows(): any {
        return [];
    }

}